import styled from "@emotion/styled";
import React from "react";
import tw from "twin.macro";
import { Responsive } from "../utils/Responsive";
import { FixedHeading } from "./FixedHeading";

interface InfoPage {
    title: string;
    image?: { src: string };
}

const InfoPage: React.FC<InfoPage> = ({ title, children, image }) => {
    return (
        <>
            <FixedHeading>{title}</FixedHeading>
            {image && <Img src={image.src} />}
            <Responsive
                sm={<MobileContent>{children}</MobileContent>}
                md={<Content>{children}</Content>}
            />
        </>
    );
};

export { InfoPage };

/* 
      Content lays at the bottom half of the screen. 
      It starts at the bottom, and as content gets larger it expands to halfway up the screen. 
      When it reaches halfway, it will extend downwards into a scroll.
*/

const MobileContent = ({ children }) => {
    return (
        <MobileContentWrapper>
            <div style={{ height: "50vh" }} />
            <div tw="flex items-end">
                <div>{children}</div>
            </div>
        </MobileContentWrapper>
    );
};

const Content = ({ children }) => {
    return (
        <ContentWrapper>
            <div />
            <div tw="grid" style={{ gridTemplateColumns: "1fr 2fr 1fr" }}>
                <div />
                <div>{children}</div>
                <div />
            </div>
        </ContentWrapper>
    );
};

const Img = ({ src }) => {
    const handleScroll = () => {
        if (typeof window !== `undefined`) {
            let pageHeight = window.innerHeight;
            window.scrollTo({ top: pageHeight * 0.9, behavior: "smooth" });
        }
    };
    return (
        <ImgWrapper>
            <img
                tw="max-h-full cursor-pointer"
                src={src}
                onClick={handleScroll}
            />
        </ImgWrapper>
    );
};

const MobileContentWrapper = styled.div`
    ${tw`grid w-full min-h-screen px-5 2xl:px-8 pb-16`};
    grid-template-rows: auto auto;
`;

const ContentWrapper = styled.div`
    ${tw`grid w-full min-h-screen -mt-16 px-5 2xl:px-8 py-3 2xl:py-6`};
    grid-template-rows: 50vh 1fr;
`;

const ImgWrapper = styled.div`
    ${tw`flex justify-end justify-center items-center inset-0`};
    margin-top: 35vh;
    z-index: -1;
    height: calc(60vh - 6rem);
`;
