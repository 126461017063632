import { graphql } from "gatsby";
import React from "react";
import { InfoPage } from "../components/InfoPage";
import { Layout } from "../components/Layout";
import Metadata from "../components/Metadata";
import { RichText } from "../components/RichText";

const TermsAndConditions = ({ data }) => {
    const { title, content } = data.contentfulTermsAndConditionsPage;
    return (
        <Layout>
            <Metadata title="Terms and Conditions" desc="" />
            <InfoPage title={title}>
                <RichText>{content}</RichText>
            </InfoPage>
        </Layout>
    );
};

export default TermsAndConditions;

export const query = graphql`
    query TermsAndConditionsPageQuery {
        contentfulTermsAndConditionsPage {
            id
            title
            # description {
            #     description
            # }
            content {
                raw
            }
        }
    }
`;
