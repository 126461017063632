import styled from "@emotion/styled";
import React from "react";
import tw from "twin.macro";
import { H1 } from "./Typography";

const FixedHeading = ({ children }) => {
    return (
        <Wrapper>
            <H1>{children}</H1>
        </Wrapper>
    );
};

export { FixedHeading };

const Wrapper = styled.div`
    ${tw`inset-0 fixed w-screen flex justify-center items-center pointer-events-none`};
    height: 50vh;
`;
